// @ts-nocheck

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faWallet,
  faAsterisk,
  faMoneyBill,
  faSearchDollar,
  faChartLine,
  faTicketAlt,
  faUsers,
  faCubes,
  faSyncAlt,
  faCertificate,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faMinus,
  faImages,
  faHighlighter,
  faTachometerAlt,
  faSync,
  faTrash,
  faPortrait,
  faCopy,
  faListAlt,
  faUserMinus,
  faInfoCircle,
  faUserAlt,
  faSchool,
  faCodeBranch,
  faDiceD6,
  faClipboardList,
  faPencilAlt,
  faExclamationCircle,
  faMailBulk,
  faMobile,
  faMagic,
  faUserCog,
  faToolbox
} from "@fortawesome/free-solid-svg-icons";

import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  install: Vue => {
    library.add(
      faSignOutAlt,
      faGoogle,
      faMicrosoft,
      faWallet,
      faAsterisk,
      faMoneyBill,
      faSearchDollar,
      faChartLine,
      faTicketAlt,
      faUsers,
      faCubes,
      faSyncAlt,
      faCertificate,
      faChevronUp,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faPlus,
      faMinus,
      faImages,
      faHighlighter,
      faTachometerAlt,
      faSync,
      faTrash,
      faPortrait,
      faCopy,
      faListAlt,
      faUserMinus,
      faInfoCircle,
      faUserAlt,
      faSchool,
      faCodeBranch,
      faDiceD6,
      faClipboardList,
      faPencilAlt,
      faExclamationCircle,
      faMailBulk,
      faMobile,
      faMagic,
      faUserCog,
      faToolbox
    );
    Vue.component("fa", FontAwesomeIcon);
  }
};
