import { apiClient } from "@/services/api";
import { AssessmentAIActions, AssessmentAIMutations } from "@/types/assessment-ai";
import { ActionTree, GetterTree, MutationTree } from "vuex"

const STORAGE_KEY = "assessment-ai-tool"

const VUE_APP_AI_TEMPERATURE = parseFloat(process.env.VUE_APP_AI_TEMPERATURE ?? '1.0')
const VUE_APP_AI_TIMEOUT = parseInt(process.env.VUE_APP_AI_TIMEOUT ?? '1800')
const VUE_APP_AZURE_OPENAI_ENDPOINT = process.env.VUE_APP_AZURE_OPENAI_ENDPOINT ?? 'https://es-ai-tutor-azure-openai-uksouth.openai.azure.com/'
const VUE_APP_AZURE_OPENAI_VERSION = process.env.VUE_APP_AZURE_OPENAI_VERSION ?? '2024-10-21'
const VUE_APP_DEPLOYMENT_NAME = process.env.VUE_APP_DEPLOYMENT_NAME ?? 'gpt-4o-mini'

const setItem = (key: string, value: any) => {
  localStorage.setItem(`${STORAGE_KEY}-${key}`, JSON.stringify(value))
}

const getItem = (key: string) => {
  const item = localStorage.getItem(`${STORAGE_KEY}-${key}`)
  return item ? JSON.parse(item) : null
}

const pollRequests: Array<{ id: string; status: string }> = [];
const sittingIdMapping: Record<string, string> = {}

const getSpeakingAIResponseForSittingId = async (userId: string, sittingId: string, systemPrompt: string, userPrompt: string, gptConfiguration) => {
  try {
    const { data } = await apiClient.post('/dev/cms/speaking-ai-playground', {
      'user_id': userId,
      'system_prompt': systemPrompt,
      'user_prompt': userPrompt,
      'sitting_ids': [sittingId],
      'service_to_use': 'assembly-ai',
      'gpt_configuration': {
        'temperature': gptConfiguration.temperature,
        'timeout': gptConfiguration.timeout,
        'azure_openai_endpoint': gptConfiguration.azureOpenaiEndpoint,
        'azure_openai_version': gptConfiguration.azureOpenaiVersion,
        'deployment_name': gptConfiguration.deploymentName
      }
    })
    return data
  } catch (err) {
    console.error(err)
    throw new Error('Unable to fetch details at the moment.')
  }
}

enum UIState {
  NONE = "none",
  SYSTEM_PROMPT = "systemPrompt",
  USER_PROMPT = "userPrompt",
  SITTING_IDS = "sittingIds",
  SITTING_RESULTS = "sittingsResults",
}

enum ServiceToUse {
  ASSEMBLY_AI = "assembly-ai",
  GPT_4o_REALTIME = "gpt-40-realtime"
}

interface AssessmentAIState {
  systemPrompt: string;
  userPrompt: string;
  sittingIds: string[];
  serviceToUse: ServiceToUse
  gptConfiguration: {
    temperature: number;
    timeout: number;
    azureOpenaiEndpoint: string;
    azureOpenaiVersion: string;
    deploymentName: string;
  },
  uiState: UIState;
  sittingsResults: Record<string, unknown | Error>
}

const state: AssessmentAIState = {
  systemPrompt: getItem("systemPrompt") || "",
  userPrompt: getItem("userPrompt") || "",
  sittingIds: [],
  serviceToUse: ServiceToUse.ASSEMBLY_AI,
  // gptConfiguration: JSON.parse(getItem("gptConfiguration") ?? JSON.stringify({
  //   temperature: 1.0,
  //   timeout: 1800,
  //   azureOpenaiEndpoint: "https://es-ai-tutor-azure-openai-uksouth.openai.azure.com/",
  //   azureOpenaiVersion: "2024-10-21",
  //   deploymentName: "gpt-4o-mini"
  // })),
  gptConfiguration: {
    temperature: VUE_APP_AI_TEMPERATURE,
    timeout: VUE_APP_AI_TIMEOUT,
    azureOpenaiEndpoint: VUE_APP_AZURE_OPENAI_ENDPOINT,
    azureOpenaiVersion: VUE_APP_AZURE_OPENAI_VERSION,
    deploymentName: VUE_APP_DEPLOYMENT_NAME
  },
  uiState: UIState.SYSTEM_PROMPT,
  sittingsResults: {}
}

const getters: GetterTree<AssessmentAIState, unknown> = {
  uiState: (state) => state.uiState,
  systemPrompt: (state) => state.systemPrompt,
  userPrompt: (state) => state.userPrompt,
  sittingIds: (state) => state.sittingIds,
  serviceToUse: (state) => state.serviceToUse,
  gptConfiguration: (state) => state.gptConfiguration,
  getSittingResults: (state) => (sittingId: string) => sittingId in state.sittingsResults ? state.sittingsResults[sittingId] : null,
  nextDisabled: (state) => {
    switch (state.uiState) {
      case UIState.SYSTEM_PROMPT:
        return !state.systemPrompt
      case UIState.USER_PROMPT:
        return !state.userPrompt
      case UIState.SITTING_IDS:
        return !(state.sittingIds).length
      default:
        return false
    }
  },
  previousDisabled: (state) => {
    return state.uiState === UIState.SYSTEM_PROMPT
  },
  renderComponent: (state) => {
    switch (state.uiState) {
      case UIState.SYSTEM_PROMPT:
        return {
          component: "es-prompt-editor",
          props: {
            key: 'es-system-prompt',
            label: 'System Prompt',
            modelValue: state.systemPrompt
          },
          on: {
            'update:modelValue': (value: string) => {
              state.systemPrompt = value
            }
          }
        }
      case UIState.USER_PROMPT:
        return {
          component: "es-prompt-editor",
          props: {
            key: 'es-user-prompt',
            label: 'User Prompt',
            modelValue: state.userPrompt
          },
          on: {
            'update:modelValue': (value: string) => {
              state.userPrompt = value
            }
          }
        }
      case UIState.SITTING_IDS:
        return {
          component: "es-sitting-ids",
          props: {
            key: 'es-sitting-ids',
            modelValue: state.sittingIds?.join(', '),
            label: 'Sitting IDs'
          },
          on: {
            'update:modelValue': (value: string) => {
              state.sittingIds = [...new Set(value?.split(',').map((id: string) => id.trim()))]
            }
          }
        }
      case UIState.SITTING_RESULTS:
        return {
          component: "es-sitting-results",
          props: {
            key: 'es-sitting-results',
            sittingIds: state.sittingIds
          }
        }
      default:
        return {
          component: "div",
          props: {
            key: 'es-none'
          },
          on: {}
        }
    }
  },
  canDownload: (state) => {
    return Object.values(state.sittingsResults).flat().filter(item => (item !== null && typeof item === 'object')).length > 0
  },
  downloadText: (state) => {
    const count = Object.values(state.sittingsResults).flat().filter(item => (item !== null && typeof item === 'object')).length
    return count > 0 ? `Download ${count} results as CSV` : 'No sittings to download'
  }
}

const actions: ActionTree<AssessmentAIState, unknown> = {
  [AssessmentAIActions.NEXT_UI_STATE]: ({ state, commit }) => {
    switch (state.uiState) {
      case UIState.NONE:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.SYSTEM_PROMPT)
        break
      case UIState.SYSTEM_PROMPT:
        setItem("systemPrompt", state.systemPrompt)
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.USER_PROMPT)
        break
      case UIState.USER_PROMPT:
        setItem("userPrompt", state.userPrompt)
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.SITTING_IDS)
        break
      case UIState.SITTING_IDS:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.SITTING_RESULTS)
        break
    }
  },
  [AssessmentAIActions.PREVIOUS_UI_STATE]: ({ state, commit }) => {
    switch (state.uiState) {
      case UIState.SYSTEM_PROMPT:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.NONE)
        break
      case UIState.USER_PROMPT:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.SYSTEM_PROMPT)
        break
      case UIState.SITTING_IDS:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.USER_PROMPT)
        break
      case UIState.SITTING_RESULTS:
        commit(AssessmentAIMutations.SET_UI_STATE, UIState.SITTING_IDS)
        break
    }
  },
  pollForSittingResults: async ({ commit, state }) => {
    const poll = async () => {
      const requests = pollRequests.filter(item => item.status === 'pending').map(item => item.id)
      if (requests.length === 0) return
      await Promise.all(requests.map(async (id) => {
        try {
          const { data } = await apiClient.get(`/dev/cms/speaking-ai-playground?request_id=${id}`)
          if (data.success) {

            commit(AssessmentAIMutations.SET_SITTING_RESULTS, {
              [
                sittingIdMapping[id]
              ]: data.data ? data.data : new Error(data.message ?? 'No data found')
            })

            pollRequests.find(item => item.id === id)!.status = 'completed'

            delete sittingIdMapping[id];
          }
          return true;
        } catch (err) {
          console.error(err)
        }
        return false
      }))
    }
    setInterval(poll, 30000) // Poll every 30 seconds
  },
  fetchSpeakingAIData: async ({ commit, state, rootGetters }, sittingId: string) => {
    commit(AssessmentAIMutations.SET_SITTING_RESULTS, {
      [sittingId]: null
    })
    try {
      const userId = rootGetters["account/user"].user_id
      const data = await getSpeakingAIResponseForSittingId(userId, sittingId, state.systemPrompt, state.userPrompt, state.gptConfiguration)
      setTimeout(() => {
        pollRequests.push({ id: data.id, status: 'pending' })
        sittingIdMapping[data.id] = sittingId
      }, 100)
    } catch (err) {
      console.error(err)
    }
  },
  download: async ({ state }) => {
    const escapeCSVValue = (value: string | number | null | undefined): string => {
      if (value === undefined || value === null) return '';
      const trimmed = typeof value === 'number' ? Number(value).toString() : value.trim();
      if (/["]/.test(trimmed)) {
        // Escape double quotes and wrap in quotes if necessary
        return trimmed.replace(/"/g, '""');
      }
      return `"${trimmed}"`;
    };
    // AS CSV

    const headers = [
      'Sitting ID',
      'Item ID',
      'Response ID',
      'Response',
      'Grammatical Range and Accuracy Score',
      'Grammatical Range and Accuracy Score Rationale',
      'Vocabulary Range Score',
      'Vocabulary Range Score Rationale',
      'Coherence Score',
      'Coherence Score Rationale',
      'Cohesion Score',
      'Cohesion Score Rationale',
      'Task Attempt Score',
      'Task Attempt Score Rationale',
      'Awareness of Context Score',
      'Awareness of Context Score Rationale',
      'Completion Tokens',
      'Prompt Tokens',
      'Total Tokens'
    ]

    const data = Object.values<{
      sitting_id: string,
      item_id: string,
      transcription: string,
      gpt_response?: {
        response: {
          response_id: string,
          grammatical_range_and_accuracy_score: number,
          grammatical_range_and_accuracy_score_rationale: string,
          vocabulary_range_score: number,
          vocabulary_range_score_rationale: string,
          coherence_score: number,
          coherence_score_rationale: string,
          cohesion_score: number,
          cohesion_score_rationale: string,
          task_attempt_score: number,
          task_attempt_score_rationale: string,
          awareness_of_context_score: number,
          awareness_of_context_score_rationale: string
        },
        usage: {
          completion_tokens: number,
          total_tokens: number,
          prompt_tokens: number
        }
      }
    }>(state.sittingsResults as any).flat().filter(item => typeof item === 'object').reduce<any[][]>((acc, item) => {
      return [
        ...acc,
        [
          escapeCSVValue(item.sitting_id),
          escapeCSVValue(item.item_id),
          escapeCSVValue(item.gpt_response?.response?.response_id),
          escapeCSVValue(item.transcription),
          escapeCSVValue(item.gpt_response?.response?.grammatical_range_and_accuracy_score),
          escapeCSVValue(item.gpt_response?.response?.grammatical_range_and_accuracy_score_rationale),
          escapeCSVValue(item.gpt_response?.response?.vocabulary_range_score),
          escapeCSVValue(item.gpt_response?.response?.vocabulary_range_score_rationale),
          escapeCSVValue(item.gpt_response?.response?.coherence_score),
          escapeCSVValue(item.gpt_response?.response?.coherence_score_rationale),
          escapeCSVValue(item.gpt_response?.response?.cohesion_score),
          escapeCSVValue(item.gpt_response?.response?.cohesion_score_rationale),
          escapeCSVValue(item.gpt_response?.response?.task_attempt_score),
          escapeCSVValue(item.gpt_response?.response?.task_attempt_score_rationale),
          escapeCSVValue(item.gpt_response?.response?.awareness_of_context_score),
          escapeCSVValue(item.gpt_response?.response?.awareness_of_context_score_rationale),
          escapeCSVValue(item.gpt_response?.usage?.completion_tokens),
          escapeCSVValue(item.gpt_response?.usage?.prompt_tokens),
          escapeCSVValue(item.gpt_response?.usage?.total_tokens)
        ]
      ]
    }, [])

    // console.log(data)

    const csv = [headers, ...data].map(row => row.join(',')).join('\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'sitting-results.csv'
    a.click()
  }
}

const mutations: MutationTree<AssessmentAIState> = {
  [AssessmentAIMutations.SET_UI_STATE]: (state, payload: UIState) => {
    state.uiState = payload
  },
  [AssessmentAIMutations.SET_SITTING_RESULTS]: (state, sittingResults) => {
    state.sittingsResults = {
      ...state.sittingsResults,
      ...sittingResults
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}