export enum AssessmentAIActions {
  UPDATE_SYSTEM_PROMPT = "updateSystemPrompt",
  UPDATE_USER_PROMPT = "updateUserPrompt",
  UPDATE_SITTING_IDS = "updateSittingIds",
  UPDATE_SERVICE_TO_USE = "updateServiceToUse",
  UPDATE_GPT_CONFIGURATION = "updateGPTConfiguration",
  UPDATE_SITTING_REQUESTS = "updateSittingRequests",
  UPDATE_UI_STATE = "updateUIState",
  UPDATE_MAP_REQUEST_TO_SITTING_ID = "updateMapRequestToSittingId",
  PREVIOUS_UI_STATE = "previousUIState",
  NEXT_UI_STATE = "nextUIState",
}

export enum AssessmentAIMutations {
  SET_SYSTEM_PROMPT = "setSystemPrompt",
  SET_USER_PROMPT = "setUserPrompt",
  SET_SITTING_IDS = "setSittingIds",
  SET_SERVICE_TO_USE = "setServiceToUse",
  SET_GPT_CONFIGURATION = "setGPTConfiguration",
  SET_UI_STATE = "setUIState",
  SET_SITTING_RESULTS = "setSittingResults",
  SET_SITTING_REQUESTS = "setSittingRequests",
  SET_MAP_REQUEST_TO_SITTING_ID = "setMapRequestToSittingId",
}
