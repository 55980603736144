import SimpleMDE from "./simplemde/dist/simplemde.min.js";
import "./simplemde/dist/simplemde.min.css";
import marked from "marked";
import Vue from "vue";

interface RendererOptions {
  spaces?: boolean;
  showImageText?: boolean;
}

function Renderer(this: any, options: RendererOptions = {}) {
  this.options = options;
  this.whitespaceDelimiter = this.options.spaces ? " " : "\n";
  this.showImageText =
    typeof this.options.showImageText !== "undefined"
      ? this.options.showImageText
      : true;
}

Renderer.prototype.code = function (code, lang, escaped) {
  return (
    this.whitespaceDelimiter +
    this.whitespaceDelimiter +
    code +
    this.whitespaceDelimiter +
    this.whitespaceDelimiter
  );
};
Renderer.prototype.blockquote = function (quote) {
  return "\t" + quote + this.whitespaceDelimiter;
};
Renderer.prototype.html = function (html) {
  return html;
};
Renderer.prototype.heading = function (text, level, raw) {
  return text;
};
Renderer.prototype.hr = function () {
  return this.whitespaceDelimiter + this.whitespaceDelimiter;
};
Renderer.prototype.list = function (body, ordered) {
  return body;
};
Renderer.prototype.listitem = function (text) {
  return "\t" + text + this.whitespaceDelimiter;
};
Renderer.prototype.paragraph = function (text) {
  return this.whitespaceDelimiter + text + this.whitespaceDelimiter;
};
Renderer.prototype.table = function (header, body) {
  return (
    this.whitespaceDelimiter +
    header +
    this.whitespaceDelimiter +
    body +
    this.whitespaceDelimiter
  );
};
Renderer.prototype.tablerow = function (content) {
  return content + this.whitespaceDelimiter;
};
Renderer.prototype.tablecell = function (content, flags) {
  return content + "\t";
};
Renderer.prototype.strong = function (text) {
  return text;
};
Renderer.prototype.em = function (text) {
  return text;
};
Renderer.prototype.codespan = function (text) {
  return text;
};
Renderer.prototype.br = function () {
  return this.whitespaceDelimiter + this.whitespaceDelimiter;
};
Renderer.prototype.del = function (text) {
  return text;
};
Renderer.prototype.link = function (href, title, text) {
  return text;
};
Renderer.prototype.image = function (href, title, text) {
  return this.showImageText ? text : "";
};
Renderer.prototype.text = function (text) {
  return text;
};

const renderer = new Renderer();

const mdPreview = Vue.extend({
  props: {
    value: {
      type: String,
      required: false
    },
    previewTextLength: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      show: false,
      position: {
        top: "100%",
        left: "0"
      }
    };
  },
  computed: {
    preview() {
      if (!this.value) {
        return "";
      }
      return marked.parse(this.value);
    },
    plainPreview() {
      if (!this.value) {
        return "";
      }
      return marked(this.value, {
        renderer
      });
    }
    // isMarkDown() {
    //   const markdownPattern = /(^#)|(^-)|(\*\*)|(```)|(\[.*\]\(.*\))/;
    //   return markdownPattern.test(this.value);
    // }
  },
  methods: {
    updatePosition(event) {
      setTimeout(() => {
        const popoverHeight =
          (this.$refs.popup as HTMLDivElement)?.clientHeight ?? 250;
        const spaceBelow = window.innerHeight - event.clientY;
        if (spaceBelow < popoverHeight) {
          (this as any).position.top = `-${popoverHeight}px`;
        } else {
          (this as any).position.top = "100%";
        }
      }, 200);
    },
    handlePreview(event) {
      if ((this as any).plainPreview.length > (this as any).previewTextLength) {
        (this as any).show = true;
        (this as any).updatePosition(event);
      }
    }
  },
  template: `
    <div
      v-if="value"
      style="cursor: pointer; padding: 1em; position: relative;"
      @mouseover="handlePreview"
      @mouseleave="show = false"
    >
      <template>
        <p v-if="plainPreview.length > previewTextLength">{{ plainPreview.substring(0, previewTextLength) }} <span style="color: blue;">... more</span></p>
        <p v-else>{{ plainPreview }}</p>
        <div v-if="show" ref="popup" :style="{ position: 'absolute', top: position.top, left: position.left, zIndex: 1000, background: '#fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', padding: '.5em', borderRadius: '5px', minWidth: '300px', maxHeight: '250px', overflowY: 'auto' }">
          <div v-html="preview"></div>
        </div>
      </template>
    </div>
  `
});

export default {
  install: Vue => {
    Vue.component("md-preview", mdPreview);
    Vue.component("md-editor", {
      props: {
        value: {
          type: String,
          required: false
        },
        disabled: {
          type: Boolean,
          default: false
        },
        placeholder: {
          type: String,
          default: ""
        },
        id: {
          type: String,
          default: ""
        },
        preview: {
          type: Boolean,
          default: false
        },
        hideToolbar: {
          type: Boolean,
          default: false,
          required: false
        },
        tabSize: {
          type: Number,
          default: 2,
          required: false
        },
        styleText: {
          type: Boolean,
          default: true,
          required: false
        }
      },

      data() {
        return {
          editor: null
        };
      },

      template: `
        <textarea></textarea>
      `,
      mounted() {
        this.$el.id = this.id;
        this.editor = new SimpleMDE({
          element: this.$el,
          initialValue: this.value ?? "",
          spellChecker: false,
          placeholder: this.placeholder,
          status: true,
          tabSize: this.tabSize,
          toolbar: !this.hideToolbar && [
            "heading-1",
            "heading-2",
            "heading-3",
            "|",
            "bold",
            "italic",
            "strikethrough",
            // "heading",
            "|",
            // "quote",
            "unordered-list",
            "ordered-list",
            // "|",
            // "link",
            // "image",
            // "table",
            "|",
            "preview",
            "guide"
          ],
          previewRender: plainText => {
            return marked(plainText);
          },
          styleSelectedText: false
        });
        this.editor.codemirror.setSize(null, "350px");
        this.editor.codemirror.on("change", () => {
          this.$emit("input", this.editor.value());
        });
      },
      methods: {
        reset() {
          (this as any).editor.isPreviewActive() &&
            (this as any).editor.togglePreview();
        }
      },
      watch: {
        value(value) {
          if ((this as any).editor.value() !== value) {
            (this as any).editor.value(value ?? "");
          }
        },
        disabled(value) {
          (this as any).editor.codemirror.setOption("readOnly", value);
        }
      }
    });
  }
};
